<template>
  <!-- 我的实验-->
  <div class="virtualsimulation">
    <div class="search">
      <div class="check-group">
        <el-checkbox class="check-all" :indeterminate="isIndeterminate" v-model="checkAll"
          @change="handleCheckAllChange">全选</el-checkbox>

        <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :label="city" :key="city">{{
            city
            }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <el-input class="elinput" size="small" v-model="searchvalue" placeholder="请输入内容">
        <i class="el-icon-search el-icon_search" slot="suffix"> </i>
      </el-input>
    </div>
    <div class="itemcontain">
      <div class="item">
        <el-image class="elimage" :src="testimage" />
        <div class="item-right">
          <div class="title">
            基于仿真头模的处理牙齿根管治疗并发症--侧穿的虚拟仿真实验
          </div>
          <div class="nameicon">
            <el-image class="icon" :src="icon1" />
            <div class="name">李莲英教授</div>
          </div>

          <div class="bottomcontain">
            <div class="left">
              <!-- <div class="contain">
                <el-image class="icon" :src="icon2" />
                <div class="name">累计实验时长:</div>
                <div class="time">57分钟</div>
              </div> -->
            </div>
            <div class="right">
              <div class="item" @click="previewmaterials">
                <el-image class="elimage" :src="image4" />
                <div class="name">预习资料</div>
              </div>
              <div class="line"></div>
              <div class="item" @click="preparatoryexam">
                <el-image class="elimage" :src="image3" />
                <div class="name">预习考试</div>
              </div>
              <div class="line"></div>
              <div class="item" @click="experimentalresults">
                <el-image class="elimage" :src="image1" />
                <div class="name">实验成绩</div>
              </div>
              <div class="line"></div>
              <div class="item" @click="experimentalreport">
                <el-image class="elimage" :src="image2" />
                <div class="name">实验报告</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>

    <Customdialog ref="customdialog" title="实验成绩" :showclose="true" width="800px">
      <div slot="dialogbody" class="dialogbody">
        <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table"
          :header-cell-style="{
            'font-size': '15px',
            color: '#666666',
            'font-weight': 'bold',
            background: '#F7F7F7',
          }" :row-style="{
            'font-size': '15px',
            color: '#222222',
            'font-weight': '400',
          }">
          <el-table-column prop="name" label="实验名称"> </el-table-column>
          <el-table-column prop="score" label="实验成绩"> </el-table-column>
          <el-table-column prop="score2" label="报告成绩"> </el-table-column>
          <el-table-column prop="score3" label="总成绩"> </el-table-column>
        </el-table>
        <div class="elpagination">
          <div class="elpagination-title">共{{ total }}条</div>
          <el-pagination background layout="prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
      <div slot="dialogfooter" class="dialogfooter"></div>
    </Customdialog>
  </div>
</template>

<script>
import Customdialog from "@/components/customdialog.vue";
const cityOptions = ["仅老师参与", "回复最多"];
export default {
  name: "virtualsimulation",
  components: {
    Customdialog,
  },
  data() {
    return {
      total: 0,
      searchvalue: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/43443.png"),
      image1: require("@/assets/coursedetails/center/图标1.png"),
      image2: require("@/assets/coursedetails/center/图标2.png"),
      image3: require("@/assets/coursedetails/center/图标3.png"),
      image4: require("@/assets/coursedetails/center/图标4.png"),
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: true,
      tableData: [
        {
          name: "王小虎",
          score: "70",
          score2: "10",
          score3: "60",
        },
      ],
    };
  },
  methods: {
    resultsbt() {
      this.$refs.customdialog.dialogopenbt();
    },
    previewmaterials() {
      this.$router.push({
        path: "/home/personalcenter/previewmaterials",
      });
    },

    preparatoryexam() {
      this.$router.push({
        path: "/home/personalcenter/previewexam",
      });
    },
    experimentalresults() {
      this.$refs.customdialog.dialogopenbt();
    },
    experimentalreport() {
       this.$router.push({
        path: "/home/personalcenter/experimentalreport2",
      });
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
  },
};
</script>
<style scoped lang="scss">


.virtualsimulation {
  .search {
    // margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .check-group {
      // border: 1px solid red;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      // margin-left: 80px;
      .check-all {
        margin-right: 20px;
      }
      .elinput {
        margin-left: 20px;
        width: 300px;
        ::v-deep .el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .elinput {
      width: 300px;
      ::v-deep .el-input__suffix {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .itemcontain {
    margin-top: 30px;
    height: 234px;
    background: #ffffff;
    padding: 30px;
    box-sizing: border-box;
    .item {
      // border: 1px solid red;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .elimage {
        width: 313px;
        height: 100%;
        background: linear-gradient(0deg, #9778af 0%, #ffffff 100%);
        border-radius: 4px;
      }
      .item-right {
        // border: 1px solid red;
        height: 100%;
        flex: 1;
        margin-left: 50px;
        .title {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #222222;
        }
        .nameicon {
          margin-top: 24px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .icon {
            margin-right: 10px;
            width: 23px;
            height: 20px;
          }
          .name {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
        .bottomcontain {
          // border: 1px solid red;
          margin-top: 20px;
          height: 85px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .left {
            // border: 1px solid red;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            .contain {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .icon {
                margin-right: 10px;
                width: 22px;
                height: 22px;
              }
              .name {
                margin-right: 10px;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
              }
              .time {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
            }
          }
          .right {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .item {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
              user-select: none;
              cursor: pointer;
              .elimage {
                width: 57px;
                height: 57px;
                border-radius: 50%;
              }
              .name {
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #666666;
              }
            }
            .line {
              margin-left: 40px;
              margin-right: 40px;
              height: 85px;
              border-right: 1px solid #e7e7e7;
            }
          }
        }
      }
    }
  }
  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .elpagination-title {
      margin-right: 4px;
    }
  }
  .dialogbody {
    padding: 20px;
    .Table {
      width: 100%;
      // border: 1px solid red;
      .item-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // margin-left: 10px;
        .title {
          margin-left: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
      .item-middle {
        .title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
      .bt {
        //   margin-right: 10px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
        padding: 0px;
        margin: 0px;
      }

      .item-right {
        display: flex;
        justify-content: center;
        align-items: center;
        .bt {
          //   margin-right: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #3d84ff;
          user-select: none;
          cursor: pointer;
        }
        .line2 {
          margin-right: 8px;
          margin-left: 8px;
          height: 14px;
          border-right: 2px solid #cccccc;
        }
      }
    }
    .elpagination {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .elpagination-title {
        margin-right: 4px;
      }
    }
  }
}
</style>
