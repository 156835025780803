import request from '@/utils/request'

//获取实验课预习资料列表
export function getcourselibpreviewlist(params) {
  return request({
    url: '/portal/course-lib-preview/get-list',
    method: 'GET',
    params
  })
}

//获取预习资料详情
export function getcourselibpreviewinfo(params) {
    return request({
      url: '/portal/course-lib-preview/get-info',
      method: 'GET',
      params
    })
  }