<template>
  <!-- 我的课表 -->
  <div class="virtualsimulation">
    <div class="search">
      <div class="leftcontain">
        <div class="left1">
          <div class="text">学期:</div>
          <el-select v-model="queryParams.semester_id" placeholder="请选择" @change="change">
            <el-option v-for="item in optionone" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="rightcontain">
        <div class="bt bt1">导出</div>
      </div>-->
    </div>
    <div class="tab-bt">
      <div class="icon-text left" @click="tabbt('left')">
        <template v-if="state == 'left'">
          <el-image class="elimage" :src="icon11" />
          <div class="text text1">表格视图</div>
        </template>
        <template v-if="state == 'right'">
          <el-image class="elimage" :src="icon1" />
          <div class="text">表格视图</div>
        </template>
      </div>
      <div class="icon-text right" @click="tabbt('right')">
        <template v-if="state == 'left'">
          <el-image class="elimage" :src="icon2" />
          <div class="text">列表视图</div>
        </template>
        <template v-if="state == 'right'">
          <el-image class="elimage" :src="icon22" />
          <div class="text text1">列表视图</div>
        </template>
      </div>
    </div>

    <template v-if="state == 'left'">
      <div class="table-tip">
        {{ semesterStr }}
        <span class="text">{{ descOfLab }}</span>
      </div>
      <div class="custom-table">
        <div class="custom-table-head">
          <div class="custom-table-head-item0 table-head-item">
            <div class="custom-table-head-item-line" />
            <span class="weektitle">星期</span>
            <span class="sessiontitle">节次</span>
          </div>
          <div class="custom-table-head-item table-head-item">星期一</div>
          <div class="custom-table-head-item table-head-item">星期二</div>
          <div class="custom-table-head-item table-head-item">星期三</div>
          <div class="custom-table-head-item table-head-item">星期四</div>
          <div class="custom-table-head-item table-head-item">星期五</div>
          <div class="custom-table-head-item table-head-item">星期六</div>
          <div class="custom-table-head-item table-head-item">星期日</div>
        </div>
        <div v-for="(item, index) in courselistlength" :key="index" class="custom-table-body">
          <div class="custom-table-body-item0 table-body-item">{{ item.name }}</div>
          <div class="custom-table-body-item table-body-item" v-for="n in 7" :key="n">
            <template v-for="(v, i) in courselist">
              <template v-if="v.lesson_id === item.id && v.day === n">
                <Item :itemdata="v" :key="i"></Item>
              </template>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-if="state == 'right'">
      <div class="main-contain">
        <div class="contain">
          <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table"
            :header-cell-style="{
            'font-size': '15px',
            color: '#666666',
            'font-weight': 'bold',
            background: '#F7F7F7',
          }" :row-style="{
            'font-size': '15px',
            color: '#222222',
            'font-weight': '400',
          }">
            <el-table-column prop="course_lib_sn" label="课程编号"></el-table-column>
            <el-table-column prop="course_lib_name" label="课程名称"></el-table-column>
            <el-table-column prop="course_type" label="课程性质"></el-table-column>
            <el-table-column prop="experiment_name" label="实验名称"></el-table-column>
            <el-table-column prop="teacher_name" label="指导老师"></el-table-column>
            <el-table-column prop="lab_room_name" label="实验用房"></el-table-column>
            <el-table-column prop="week_number" label="周次"></el-table-column>
            <el-table-column prop="day" label="星期"></el-table-column>
            <el-table-column prop="lesson" label="节次"></el-table-column>
          </el-table>
          <div class="elpagination">
            <div class="elpagination-title">共{{ total }}条</div>
            <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
              :current-page="params.page" @current-change="changePage"></el-pagination>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Item from "./item"
import { gettreelist } from "@/api/common/semester"
import { getmylessonlist, getlessonlist } from "@/api/courselibweeklylesson"

export default {
  name: "virtualsimulation",
  components: {
    Item,
  },
  provide() {
    return {
      pitem: this,
    }
  },
  data() {
    return {
      courselist: [],
      courselistlength: [],
      state: "left",
      total: 0,
      icon1: require("@/assets/coursedetails/center/表格.png"),
      icon11: require("@/assets/coursedetails/center/表格 (1).png"),
      icon2: require("@/assets/coursedetails/center/列表.png"),
      icon22: require("@/assets/coursedetails/center/列表 (1).png"),
      tableData: [],
      optionone: [],
      queryParams: {
        semester_id: '',
        page: 1,
        per_page: 9999
      },
      params: {
        semester_id: '',
        page: 1,
        per_page: 10
      },
      semesterStr: '',
      descOfLab: ''
    }
  },
  created() {
    this.gettreelist()
    this.getlessonlist()
  },
  methods: {
    tabbt(state) {
      this.state = state
      if (state == "right") {
        this.getmylessonlist2()
      } else if (state == "left") {
        this.getmylessonlist()
      }
    },
    change(item) {
      this.semesterStr = this.optionone.filter(v => { if (v.value === item) { return v.label } })[0].label
      this.queryParams.semester_id = item
      this.params.semester_id = item
      this.getmylessonlist()
    },
    changePage(val) {
      this.params.page = val
      this.getmylessonlist2()
    },
    formatSelect(_data) {
      this.optionone = _data.map(item => {
        return {
          label: item.start_year + '-' + item.end_year + '/' + (item.nper == 1 ? '第一学期' : '第二学期'),
          value: item.id,
          is_current: item.is_current,
          week_number: item.week_number
        }
      })
      this.optionone.forEach(el => {
        if (el.is_current == 1) {
          this.change(el.value)
        }
      })
    },
    async gettreelist() {
      const condition = sessionStorage.getItem('semester_all')
      if (condition) {
        let data = JSON.parse(condition)
        this.formatSelect(data.data)
      } else {
        const params = {
          page: 1,
          per_page: 100
        }
        await gettreelist(params)
          .then((response) => {
            if (response.code === 0 && response.data) {
              this.formatSelect(response.data.data)
            }
          })
          .catch((error) => {
            //console.log(error)
          })
      }
    },
    //获取我的实验排课全表
    getmylessonlist() {
      getmylessonlist(this.queryParams)
        .then((response) => {
          if (response.code === 0) {
            this.courselist = response.data.data
            //console.log(this.courselist, '-------this.courselist')
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },

    //获取我的实验排课全表table
    getmylessonlist2() {
      getmylessonlist(this.params)
        .then((response) => {
          if (response.code === 0) {
            this.tableData = response.data.data
            this.total = response.data.total
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    //获取课表长度
    getlessonlist() {
      this.courselistlength = []
      getlessonlist()
        .then((response) => {
          if (response.code === 0) {
            this.courselistlength = response.data
            //console.log(this.courselistlength, '-------this.courselistlength')
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .virtualsimulation {
    margin-bottom: 80px;

    .search {
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      background: #fcfcfc;
      border: 1px solid #ececec;

      .leftcontain {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .left1 {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .text {
            margin-right: 10px;
            font-size: 16px;

            font-weight: 400;
            color: #888888;
          }

          .elinput {
            width: 200px;

            ::v-deep .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .left2 {
          margin-left: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .text {
            margin-right: 10px;
            font-size: 16px;

            font-weight: 400;
            color: #888888;
          }

          .elinput {
            width: 300px;
          }
        }
      }

      .rightcontain {
        display: flex;
        justify-content: center;
        align-items: center;

        .bt {
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #3d84ff;
          border-radius: 2px;
          font-size: 14px;

          font-weight: 400;
          color: #3d84ff;
          user-select: none;
          cursor: pointer;
        }

        .bt1 {
          margin-right: 10px;
          background: #3d84ff;
          color: white;
        }
      }
    }

    .tab-bt {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 20px;

      .icon-text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        user-select: none;
        cursor: pointer;

        .elimage {
          width: 19px;
          height: 19px;
        }

        .text {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }

        .text1 {
          color: #3d84ff;
        }
      }

      .left {
        margin-right: 40px;
      }
    }

    .table-tip {
      margin-top: 20px;
      height: 33px;
      line-height: 33px;
      text-align: center;
      background: #3d84ff;
      border: 1px solid #3d84ff;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;

      .text {
        margin-left: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .custom-table {
      .custom-table-head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        user-select: none;

        .custom-table-head-item0 {
          position: relative;
          // flex-basis: 154px;
          height: 61px;
          background: #e7ecf9;
          border-left: 1px solid #c8c8c8;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 16px;
          font-weight: bold;
          color: #303030;
          text-align: center;

          .weektitle {
            font-size: 12px;
            font-weight: bold;
            color: #808080;
            position: absolute;
            top: 9px;
            right: 5px;
          }

          .sessiontitle {
            font-size: 12px;
            font-weight: bold;
            color: #808080;
            position: absolute;
            left: 4px;
            bottom: 8px;
          }

          .custom-table-head-item-line {
            width: 51px;
            height: 60px;
            background: linear-gradient(49deg, transparent 49%, #646363 49%, #7a7a7a 50%, transparent 50%);
          }
        }

        .custom-table-head-item {
          flex-grow: 1;
          flex-shrink: 1;
          height: 61px;
          line-height: 61px;
          background: #e7ecf9;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 12px;
          font-weight: bold;
          color: #303030;
          text-align: center;
        }

        .table-head-item {
          width: calc(100% / 8);
          box-sizing: border-box;
        }
      }

      .custom-table-body {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        user-select: none;

        .custom-table-body-item0 {
          // flex-basis: 156px;
          height: 80px;
          line-height: 80px;
          background: #ffffff;
          border-left: 1px solid #c8c8c8;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 12px;
          font-weight: bold;
          color: #303030;
          text-align: center;
        }

        .custom-table-body-item {
          flex-grow: 1;
          flex-shrink: 1;
          height: 80px;
          line-height: 80px;
          background: #ffffff;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 16px;
          font-weight: bold;
          color: #303030;
          text-align: center;
          cursor: pointer;
        }

        .table-body-item {
          width: calc(100% / 8);
          box-sizing: border-box;
        }
      }

      .custom-table-foot {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        user-select: none;

        .custom-table-foot-item0 {
          flex-basis: 156px;
          height: 80px;
          line-height: 80px;
          background: #ffffff;
          border-left: 1px solid #c8c8c8;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 16px;
          font-weight: bold;
          color: #303030;
          text-align: center;
        }

        .custom-table-foot-item {
          flex-grow: 1;
          flex-shrink: 1;
          height: 80px;
          line-height: 80px;
          background: #ffffff;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 16px;
          font-weight: bold;
          color: #303030;
          text-align: center;
        }
      }
    }

    .main-contain {
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      height: 200px;
      width: calc(100% - 20px);
      position: relative;

      .contain {
        position: absolute;
        width: 100%;
        height: 100%;

        .Table {
          width: 100%;

          .item-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .title {
              margin-left: 10px;
              font-size: 15px;

              font-weight: 400;
              color: #222222;
            }
          }

          .item-middle {
            .title {
              font-size: 15px;

              font-weight: 400;
              color: #222222;
            }
          }

          .bt {
            font-size: 15px;

            font-weight: 400;
            color: #3d84ff;
            user-select: none;
            cursor: pointer;
            padding: 0px;
            margin: 0px;
          }

          .item-right {
            display: flex;
            justify-content: center;
            align-items: center;

            .bt {
              font-size: 15px;

              font-weight: 400;
              color: #3d84ff;
              user-select: none;
              cursor: pointer;
            }

            .line2 {
              margin-right: 8px;
              margin-left: 8px;
              height: 14px;
              border-right: 2px solid #cccccc;
            }
          }
        }

        .elpagination {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          .elpagination-title {
            margin-right: 4px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .virtualsimulation {
    margin-bottom: 80px;

    .search {
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      background: #fcfcfc;
      border: 1px solid #ececec;

      .leftcontain {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .left1 {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .text {
            margin-right: 10px;
            font-size: 16px;

            font-weight: 400;
            color: #888888;
          }

          .elinput {
            width: 200px;

            ::v-deep .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .left2 {
          margin-left: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .text {
            margin-right: 10px;
            font-size: 16px;

            font-weight: 400;
            color: #888888;
          }

          .elinput {
            width: 300px;
          }
        }
      }

      .rightcontain {
        display: flex;
        justify-content: center;
        align-items: center;

        .bt {
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #3d84ff;
          border-radius: 2px;
          font-size: 14px;

          font-weight: 400;
          color: #3d84ff;
          user-select: none;
          cursor: pointer;
        }

        .bt1 {
          margin-right: 10px;
          background: #3d84ff;
          color: white;
        }
      }
    }

    .tab-bt {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 20px;

      .icon-text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        user-select: none;
        cursor: pointer;

        .elimage {
          width: 19px;
          height: 19px;
        }

        .text {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #999999;
        }

        .text1 {
          color: #3d84ff;
        }
      }

      .left {
        margin-right: 40px;
      }
    }

    .table-tip {
      margin-top: 20px;
      height: 33px;
      line-height: 33px;
      text-align: center;
      background: #3d84ff;
      border: 1px solid #3d84ff;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;

      .text {
        margin-left: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .custom-table {
      .custom-table-head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        user-select: none;

        .custom-table-head-item0 {
          position: relative;
          // flex-basis: 154px;
          height: 61px;
          background: #e7ecf9;
          border-left: 1px solid #c8c8c8;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 16px;
          font-weight: bold;
          color: #303030;
          text-align: center;

          .weektitle {
            font-size: 16px;
            font-weight: bold;
            color: #808080;
            position: absolute;
            top: 10px;
            right: 10px;
          }

          .sessiontitle {
            font-size: 16px;
            font-weight: bold;
            color: #808080;
            position: absolute;
            left: 10px;
            bottom: 10px;
          }

          .custom-table-head-item-line {
            width: 154px;
            height: 61px;
            background: linear-gradient(22deg,
                transparent 49%,
                #646363 49%,
                #7a7a7a 50%,
                transparent 50%);
          }
        }

        .custom-table-head-item {
          flex-grow: 1;
          flex-shrink: 1;
          height: 61px;
          line-height: 61px;
          background: #e7ecf9;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 16px;
          font-weight: bold;
          color: #303030;
          text-align: center;
        }

        .table-head-item {
          width: calc(100% / 8);
          box-sizing: border-box;
        }
      }

      .custom-table-body {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        user-select: none;

        .custom-table-body-item0 {
          // flex-basis: 156px;
          height: 80px;
          line-height: 80px;
          background: #ffffff;
          border-left: 1px solid #c8c8c8;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 16px;
          font-weight: bold;
          color: #303030;
          text-align: center;
        }

        .custom-table-body-item {
          flex-grow: 1;
          flex-shrink: 1;
          height: 80px;
          line-height: 80px;
          background: #ffffff;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 16px;
          font-weight: bold;
          color: #303030;
          text-align: center;
          cursor: pointer;
        }

        .table-body-item {
          width: calc(100% / 8);
          box-sizing: border-box;
        }
      }

      .custom-table-foot {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        user-select: none;

        .custom-table-foot-item0 {
          flex-basis: 156px;
          height: 80px;
          line-height: 80px;
          background: #ffffff;
          border-left: 1px solid #c8c8c8;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 16px;
          font-weight: bold;
          color: #303030;
          text-align: center;
        }

        .custom-table-foot-item {
          flex-grow: 1;
          flex-shrink: 1;
          height: 80px;
          line-height: 80px;
          background: #ffffff;
          border-right: 1px solid #c8c8c8;
          border-bottom: 1px solid #c8c8c8;
          font-size: 16px;
          font-weight: bold;
          color: #303030;
          text-align: center;
        }
      }
    }

    .main-contain {
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      height: 200px;
      width: calc(100% - 20px);
      position: relative;

      .contain {
        position: absolute;
        width: 100%;
        height: 100%;

        .Table {
          width: 100%;

          .item-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .title {
              margin-left: 10px;
              font-size: 15px;

              font-weight: 400;
              color: #222222;
            }
          }

          .item-middle {
            .title {
              font-size: 15px;

              font-weight: 400;
              color: #222222;
            }
          }

          .bt {
            font-size: 15px;

            font-weight: 400;
            color: #3d84ff;
            user-select: none;
            cursor: pointer;
            padding: 0px;
            margin: 0px;
          }

          .item-right {
            display: flex;
            justify-content: center;
            align-items: center;

            .bt {
              font-size: 15px;

              font-weight: 400;
              color: #3d84ff;
              user-select: none;
              cursor: pointer;
            }

            .line2 {
              margin-right: 8px;
              margin-left: 8px;
              height: 14px;
              border-right: 2px solid #cccccc;
            }
          }
        }

        .elpagination {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          .elpagination-title {
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>
