<template>
  <!-- 预习资料 -->
  <div class="virtualsimulation">
    <el-table
      ref="singleTable"
      :data="tableData"
      highlight-current-row
      style="width: 100%"
      class="Table"
      :header-cell-style="{
        'font-size': '15px',
        color: '#666666',
        'font-weight': 'bold',
        background: '#F7F7F7',
      }"
      :row-style="{
        'font-size': '15px',
        color: '#222222',
        'font-weight': '400',
      }"
    >
      <el-table-column prop="preview_name" label="资料名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="course_lib_sn" label="课程编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="course_lib_name" label="课程名" show-overflow-tooltip></el-table-column>
      <el-table-column prop="experiment_name" label="实验项目" show-overflow-tooltip></el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="item-right">
            <div class="bt" @click="look(scope.row)">查看</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        :current-page="queryParams.page"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getcourselibpreviewlist } from "@/api/courselibpreview"

export default {
  name: "virtualsimulation",
  components: {

  },
  data () {
    return {
      timevalue: "",
      total: 0,
      searchvalue: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/43443.png"),
      tableData: [],
      queryParams: {
        course_lib_id: '',
        page: 1,
        per_page: 10
      }
    }
  },
  methods: {
    initdata (item) {
      this.queryParams.course_lib_id = item.id
      this.getcourselibpreviewlist()
    },
    look (item) {
      //console.log('item', item)
      this.$router.push({
        path: "/home/personalcenter/previewmaterialdetails",
        query: {
          course_lib_name: item.course_lib_name,
          experiment_name: item.experiment_name,
          id: item.id,
        },
      })
    },
    getcourselibpreviewlist () {
      const params = {
        course_lib_id: this.queryParams.course_lib_id,
        page: this.queryParams.page,
        per_page: this.queryParams.per_page
      }
      getcourselibpreviewlist(params)
        .then((response) => {
          this.tableData = response.data.data
          this.total = response.data.total
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    changePage (val) {
      this.queryParams.page = val
      this.getcourselibpreviewlist()
    }

  },
};
</script>
<style scoped lang="scss">
.virtualsimulation {
  .search {
    // margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;
    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 220px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
        .elselect {
          width: 100px;
        }
      }
    }
    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;
      .bt {
        width: 64px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        font-size: 14px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }
      .bt1 {
        margin-left: 10px;
        background: #3d84ff;
        color: white;
      }
    }
  }

  .Table {
    padding: 10px;
    margin-top: 30px;
    width: 100%;
    // border: 1px solid red;

    .bt {
      //   margin-right: 10px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
    }
  }
  .elpagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .elpagination-title {
      margin-right: 4px;
    }
  }
  ::v-deep .el-table::before {
    height: 0 !important;
  }
  // .elpagination {
  //   margin-top: 40px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   .elpagination-title {
  //     margin-right: 4px;
  //   }
  // }
}
</style>
