import request from '@/utils/request'

//获取我的实验排课全表
export function getmylessonlist(params) {
    return request({
        url: '/portal/course-lib-weekly-lesson/get-my-lesson-list',
        method: 'GET',
        params
    })
}

//获取我的实验课单课节
export function getmysinglelessonlist(params) {
    return request({
        url: '/portal/course-lib-weekly-lesson/get-my-single-lesson-list',
        method: 'GET',
        params
    })
}

  //获取课程列表长度
  export function getlessonlist(params) {
    return request({
      url: '/common/lesson/get-list',
      method: 'GET',
      params
    })
  } 

    //获取我的实验课考勤记录
    export function getmyattendancelist(params) {
      return request({
        url: '/portal/course-lib-weekly-lesson/get-my-attendance-list',
        method: 'GET',
        params
      })
    }