<template>
  <div v-loading="loading"
    element-loading-text="图片上传中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.5)"
    class="updateImgCont">
    <!-- 云 -->
    <template v-if="whether == 'true'">
      <el-upload class="avatar-uploader-item" action="#" :show-file-list="false" :http-request="httpRequest">
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
        <video v-else-if="videoUrl" :src="videoUrl" class="avatar" controls="controls" controlslist="nodownload">
          加载失败
        </video>
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </template>
    <!-- 本地 -->
    <template v-if="whether == 'false'">
      <el-upload class="avatar-uploader-item" :action="uploadSrc" :show-file-list="false" :headers="headers" :data="fileData"
        :on-success="handleAvatarSuccess" :on-change="handleOnChange">
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
        <video v-else-if="videoUrl" :src="videoUrl" class="avatar" controls="controls" controlslist="nodownload">
          加载失败
        </video>
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </template>

  </div>

</template>

<script>
import { uploadFile, getExt } from "@/utils/upload";
import md5 from "js-md5";
import { getToken } from "@/utils/auth";
const schoolid = sessionStorage.getItem("schoolid") * 1;
const date = new Date()
const c_time = Math.trunc(date.getTime() / 1000);
let sign_time = c_time.toString();
let app_version = 'v1.0';
export default {
  props: {
    // 回显的图片src
    imgSrc: {
      type: String
    },
    // 循环里面得封面用得着
    loops: {
      type: Object
    },
    // 视频
    videoUrl: {
      type: String,
    },
    // 云文件区分
    cloudType: {
      type: String
    },
		// 业务模块名称
		module: {
			type: String,
			default: "",
		},
  },
  data() {
    return {
      imageUrl: '',
      uploadSrc: process.env.VUE_APP_BASE_API + '/common/upload-file/upload',
      headers: {
        Authorization: "Bearer " + getToken(),
        fromtype: 2,
        identifier: 2,
        schoolid: schoolid ? schoolid : 1,
        'app-version': app_version,
        'request-time': c_time,
        'request-sign': md5(md5(sign_time) + app_version)
      },
			// 上传时附带的额外参数
			fileData: {
				module: this.module,
			},
      loading: false,
    }
  },
  watch: {
    imgSrc: {
      handler(val) {
        if (val) {
          this.imageUrl = val
        } else {
          this.imageUrl = ''
        }
      },
      immediate: true
    }
  },
  created() {
    this.whether = sessionStorage.getItem("whether")

    const date = new Date()
    const c_time = Math.trunc(date.getTime() / 1000)
    const sign_time = c_time.toString()
    const app_version = 'v1.0'
    this.headers['app-version'] = app_version
    this.headers['request-time'] = c_time
    this.headers['request-sign'] = md5(md5(sign_time) + app_version)
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      let FileArr = []
      if (res.code === 0) {
        const params = {
          url: file.response.data[0].path,
          name: file.name,
          size: file.size,
          ext: file.response.data[0].ext
        }
        FileArr.push(params)
        if (this.loops) {
          const params = {
            src: FileArr,
            loop: this.loops
          }
          this.$emit('setUrl', params)
        } else {
          this.$emit('setUrl', JSON.stringify(FileArr))
        }
      }
    },
    httpRequest(option) {
      uploadFile(option.file, this.afterupload, this.cloudType)
    },
    // 上传的回调函数
    afterupload(file, data) {
      this.imageUrl = URL.createObjectURL(file);
      const item = {
        url: data.Key,
        name: file.name,
        size: file.size,
        ext: file.name.substring(file.name.lastIndexOf(".") + 1)
      }
      if (this.loops) {
        const params = {
          src: [item],
          loop: this.loops
        }
        this.$emit('setUrl', params)
      } else {
        this.$emit('setUrl', JSON.stringify([item]))
      }
    },
    handleOnChange(res, file) {
      let condition = res?.status ?? ''
      if (condition.indexOf('success') !== -1) {
        this.$message.success('图片上传成功!')
        this.loading = false
      } else if (condition.indexOf('ready') !== -1) {
        this.loading = true
      } else if (condition.indexOf('error') !== -1) {
        this.$message.error('图片上传失败!')
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-uploader-item .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px dashed #ccc;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #ccc;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>