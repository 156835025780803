<template>
  <div class="mainpage">
    <div class="maincontain">
      <div class="contain">
        <div class="leftcontain">
          <div class="left-bts">
            <div :class="activebtid == index
                  ? 'btitemcontain btitemcontain-active'
                  : 'btitemcontain'
                " @click="clickbt(index, item, $event)" v-for="(item, index) in btlist" :key="index">
              <div class="btleft">
                <el-image class="elimage" :src="item.icon1" v-if="activebtid != index" />
                <el-image class="elimage" :src="item.icon2" v-else />
                <div class="btname">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightcontain">
          <component :is="componentId"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Virtualsimulationexperiment from "./virtualsimulationexperiment"
import Mycourses from "./mycourses"
import Teachingexperimentclass from "./teachingexperimentclass"
import Myexperiment from "./myexperiment"
import Openroomreservation from "./openroomreservation"
import Openexperiment from "./openexperiment"
import Openexperimentappointment from "./openexperimentappointment"
import Myinternship from "./myinternship"
import Mycollection from "./mycollection"
import Mycertificate from "./mycertificate"
import Safeaccess from "./safeaccess"
import Notification from "./notification"
import Personalinfor from "./personalinfor"
import Myclassschedule from "./myclassschedule"

export default {
  name: "mainpage",
  components: {
    Virtualsimulationexperiment,
    Mycourses,
    Teachingexperimentclass,
    Myexperiment,
    Openroomreservation,
    Openexperiment,
    Openexperimentappointment,
    Myinternship,
    Mycollection,
    Mycertificate,
    Safeaccess,
    Notification,
    Personalinfor,
    Myclassschedule,
  },
  data() {
    return {
      activebtid: 0,
      componentId: "Virtualsimulationexperiment",
      total: 0,
      searchvalue: "",
      testimage: require("@/assets/test.png"),
      testimageicon: require("@/assets/矩形图标.png"),
      icon1: require("@/assets/coursedetails/考试 (1).png"),

      btlist: [
        {
          name: "虚拟仿真实验",
          component: "Virtualsimulationexperiment",
          icon1: require("@/assets/coursedetails/center/main/1.png"),
          icon2: require("@/assets/coursedetails/center/main/1.1.png"),
        },
        // {
        //   name: "我的线上课程",
        //   component: "Mycourses",
        //   icon1: require("@/assets/coursedetails/center/main/2.png"),
        //   icon2: require("@/assets/coursedetails/center/main/2.1.png"),
        // },
        // {
        //   name: "我的实验",
        //   component: "Myexperiment",
        //   icon1: require("@/assets/coursedetails/center/组 6 (2).png"),
        //   icon2: require("@/assets/coursedetails/center/组 6 (2)1.png"),
        // },
        // {
        //   name: "教学实验课",
        //   component: "Teachingexperimentclass",
        //   icon1: require("@/assets/coursedetails/center/main/3.png"),
        //   icon2: require("@/assets/coursedetails/center/main/3.1.png"),
        // },
        // {
        //   name: "开放用房预约",
        //   component: "Openroomreservation",
        //   icon1: require("@/assets/coursedetails/center/main/4.png"),
        //   icon2: require("@/assets/coursedetails/center/main/4.1.png"),
        // },
        // {
        //   name: "开放实验预约",
        //   component: "Openexperiment",
        //   icon1: require("@/assets/coursedetails/center/main/10.png"),
        //   icon2: require("@/assets/coursedetails/center/main/10.1.png"),
        // },
        // {
        //   name: "我的课表",
        //   component: "Myclassschedule",
        //   icon1: require("@/assets/coursedetails/center/main/5.png"),
        //   icon2: require("@/assets/coursedetails/center/main/5.1.png"),
        // },
        // {
        //   name: "我的实习",
        //   component: "Myinternship",
        //   icon1: require("@/assets/coursedetails/center/main/6.png"),
        //   icon2: require("@/assets/coursedetails/center/main/6.1.png"),
        // },
        // {
        //   name: "安全准入",
        //   component: "Safeaccess",
        //   icon1: require("@/assets/组 6.png"),
        //   icon2: require("@/assets/组 6.png"),
        // },

        {
          name: "我的收藏",
          component: "Mycollection",
          icon1: require("@/assets/coursedetails/center/main/7.png"),
          icon2: require("@/assets/coursedetails/center/main/7.1.png"),
        },
        // {
        //   name: "我的证书",
        //   component: "Mycertificate",
        //   icon1: require("@/assets/coursedetails/center/main/9.png"),
        //   icon2: require("@/assets/coursedetails/center/main/9.1.png"),
        // },
        {
          name: "消息通知",
          component: "Notification",
          icon1: require("@/assets/coursedetails/center/main/8.png"),
          icon2: require("@/assets/coursedetails/center/main/8.1.png"),
        },
        {
          name: "个人信息",
          component: "Personalinfor",
          icon1: require("@/assets/coursedetails/center/main/9.png"),
          icon2: require("@/assets/coursedetails/center/main/9.1.png"),
        },
      ],
    }
  },
  created() {
    let activePage = JSON.parse(sessionStorage.getItem('my-page'))
    this.activebtid = this.$route.query.id ? this.$route.query.id : activePage.active
    this.componentId = this.$route.query.componentId ? this.$route.query.componentId : activePage.componentId

    // let identifier = this.$store.state.identifier
    // if (identifier == 3) {
    //   this.btlist.splice(2, 5)
    // }
  },
  methods: {
    clickbt(index, item, event) {
      // this.activebtid = index
      this.componentId = item.component
      let mypage = {
        active: index,
        componentId: item.component
      }
      sessionStorage.setItem('my-page', JSON.stringify(mypage))
      if (index != this.activebtid) {
        this.activebtid = index
        // 计算当前按钮的位置，看是否需要移动
        const spanLeft = event?.clientX || 0; // 当前点击的元素左边距离
        console.log('spanLeft', spanLeft);
        const divBox = document.querySelector(".left-bts .btitemcontain").clientWidth / 2; // 点击的元素一半宽度
        console.log('divBox', divBox);
        const totalWidths = document.body.clientWidth; // 屏幕总宽度
        console.log('totalWidths', totalWidths);
        const widths = totalWidths / 2; // 一半的屏幕宽度
        console.log('widths', widths);
        const spanRight = totalWidths - spanLeft; // 元素的右边距离
        console.log('spanRight', spanRight);
        const scrollBox = document.querySelector(".left-bts"); // 获取最外层的元素
        const scrollL = scrollBox.scrollLeft; // 滚动条滚动的距离
        // 当元素左边距离 或者 右边距离小于100时进行滑动
        if (spanRight < 400 || spanLeft < 400) {
          scrollBox.scrollLeft = scrollL + (spanLeft - widths) + divBox;
          console.log('scrollBox.scrollLeft', scrollBox.scrollLeft)
        }
      }
    },
    todetail() {
      this.$router.push({
        path: "/openroom/roomdetails",
      })
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .mainpage {
    .maincontain {
      border-radius: 4px;
      // padding: 30px;

      .contain {
        height: 100%;
        width: 100%;
        // display: flex;
        // justify-content: space-between;
        // align-items: stretch;

        .leftcontain {
          // width: 220px;
          // min-height: calc(100vh - 240px);
          background: #ffffff;
          white-space: nowrap;
          margin-top: 67px;
          overflow-x: auto;
          width: 100%;

          .btitemcontain {
            width: 180px;
            height: 48px;
            background: #ffffff;
            color: #333333;
            box-shadow: 0px 0px 1px 0px #ffffff;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            // user-select: none;
            cursor: pointer;
            line-height: 48px;
            display: inline-block;
            // padding: 0 20px;
            .btleft {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .elimage {
                margin-left: 10px;
                margin-right: 10px;
                width: 20px;
                height: 20px;
              }

              .btname {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
              }
            }
          }

          .btitemcontain-active {
            background: #3d84ff;
            color: #ffffff !important;
          }
        }

        .rightcontain {
          // margin-left: 20px;
          height: 100%;
          // min-height: calc(100vh - 240px);
          width: 100%;
          // flex: 1;
          width: 100%;
          background-color: #fff;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .mainpage {
    .maincontain {
      border-radius: 4px;
      padding: 30px;

      .contain {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .leftcontain {
          width: 220px;
          min-height: calc(100vh - 240px);
          background: #ffffff;

          .btitemcontain {
            width: 220px;
            height: 48px;
            background: #ffffff;
            color: #333333;
            box-shadow: 0px 0px 1px 0px #ffffff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .btleft {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .elimage {
                margin-left: 10px;
                margin-right: 10px;
                width: 20px;
                height: 20px;
              }

              .btname {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
              }
            }
          }

          .btitemcontain-active {
            background: #3d84ff;
            color: #ffffff !important;
          }
        }

        .rightcontain {
          margin-left: 20px;
          height: 100%;
          min-height: calc(100vh - 240px);
          min-width: 80%;
          flex: 1;
          background-color: #fff;
        }
      }
    }
  }
}
</style>
