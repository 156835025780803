<template>
  <div class="virtualsimulation">
    <!-- 教学实验课 -->
    <div class="search">
      <div class="leftcontain">
        <div class="left1">
          <div class="text">关键字:</div>
          <el-input class="elinput" v-model="queryParams.keyword" placeholder="课程名/课程编号/主讲老师">
            <i class="el-icon-search el-icon_search" slot="suffix"></i>
          </el-input>
        </div>
        <div class="left2">
          <div class="text">课程性质:</div>
          <el-select v-model="queryParams.course_classify_config_id" placeholder="请选择">
            <el-option v-for="item in courseoption" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="left2">
          <div class="text">学年学期:</div>
          <el-select v-model="queryParams.valueone" placeholder="请选择">
            <el-option v-for="item in optionone" :key="item.value" :label="item.label" :value="item.value"
              @click.native="change(item)"></el-option>
          </el-select>
          <!-- <el-select style="margin-left: 10px" v-model="queryParams.valuetwo" placeholder="请选择">
            <el-option v-for="item in optiontwo" :key="item.value" :label="item.label" :value="item.value"
              @click.native="change2(item)">
            </el-option>
          </el-select>-->
        </div>
      </div>
      <div class="rightcontain">
        <el-button type="primary" @click="search" style="background:#3d84ff">查询</el-button>
        <el-button type="info" @click="reset">重置</el-button>
        <!-- <el-button class="bt bt1" @click="reset">重置</el-button>
        <el-button class="bt" @click="search">查询</el-button>-->
      </div>
    </div>
    <div class="parentt">
      <div class="contain">
        <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table"
          :header-cell-style="{ 'font-size': '15px', color: '#666666', 'font-weight': 'bold', background: '#F7F7F7', }"
          :row-style="{ 'font-size': '15px', color: '#222222', 'font-weight': '400', }">
          <el-table-column prop="course_lib_sn" label="课程编号"></el-table-column>
          <el-table-column prop="name" label="课程名称"></el-table-column>
          <el-table-column prop="course_type" label="课程性质"></el-table-column>
          <el-table-column prop="teacher_name" label="主讲老师"></el-table-column>
          <el-table-column label="操作" align="center" width="400">
            <template slot-scope="scope">
              <div class="item-right">
                <div class="bt" @click="bt1(scope.row)">课程安排</div>
                <div class="bt" @click="bt2(scope.row)">预习资料</div>
                <div class="bt" @click="bt3(scope.row)">实验报告</div>
                <div class="bt" @click="bt4(scope.row)">考勤记录</div>
                <div class="bt" @click="bt5(scope.row)">教学成绩</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="elpagination" v-if="total > 0">
          <div class="elpagination-title">共{{ total }}条</div>
          <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
    </div>

    <!-- 课程安排 -->
    <Customdialog ref="customdialog1" type="table" title="课程安排" :showclose="true" width="60%">
      <div slot="dialogbody" class="dialogbody">
        <Classscheduletable ref="classscheduletable"></Classscheduletable>
      </div>
    </Customdialog>

    <!-- 预习资料 -->
    <Customdialog ref="customdialog2" type="table" title="预习资料" :showclose="true" width="60%">
      <div slot="dialogbody" class="dialogbody">
        <Previewmaterials ref="previewmaterials"></Previewmaterials>
      </div>
    </Customdialog>

    <!-- 考勤记录 -->
    <Customdialog ref="customdialog3" type="table" title="考勤记录" :showclose="true" width="60%">
      <div slot="dialogbody" class="dialogbody">
        <Attendancerecord ref="attendancerecord"></Attendancerecord>
      </div>
    </Customdialog>

    <!-- 教学成绩 -->
    <Customdialog ref="customdialog4" type="min" title="教学成绩" :showclose="true" width="60%">
      <div slot="dialogbody" class="dialogbody">
        <Teachinggrade ref="teachinggrade"></Teachinggrade>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import { gettreelist } from "@/api/common/semester"
import { gettypeconfiglist } from "@/api/typeconfig"
import { getmyarrangedlist } from "@/api/courselibtimetable"
import Customdialog from "@/components/customdialog.vue"
import Classscheduletable from "./classscheduletable.vue"
import Previewmaterials from "./previewmaterials.vue"
import Attendancerecord from "./attendancerecord.vue"
import Teachinggrade from "./teachinggrade.vue"
export default {
  name: "virtualsimulation",
  components: {
    Customdialog,
    Classscheduletable,
    Previewmaterials,
    Attendancerecord,
    Teachinggrade,
  },
  data() {
    return {
      timevalue: "",
      total: 0,
      searchvalue: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/43443.png"),
      tableData: [],
      queryParams: {
        keyword: "",
        semester_id: "",
        course_classify_config_id: "",
        valueone: "",
        valuetwo: "",
      },
      courseoption: [],
      optionone: [],
      // optiontwo: [],
      is_current_id: "",
      is_current_weeknum: "",
    }
  },
  created() {
    this.gettreelist()
    this.gettypeconfiglist()
    // this.getmyarrangedlist();
  },
  methods: {
    change(item) {
      this.queryParams.valueone = item.label
      this.is_current_id = item.value
    },
    reset() {
      this.queryParams.keyword = ""
      this.queryParams.course_classify_config_id = ""
      this.queryParams.valueone = ""
      this.getmyarrangedlist()
    },
    search() {
      this.getmyarrangedlist()
    },
    bt1(item) {
      this.$refs.customdialog1.dialogopenbt()
      this.$nextTick(() => {
        this.$refs.classscheduletable.initdata(item, this.is_current_weeknum)
      })
    },
    bt2(item) {
      this.$refs.customdialog2.dialogopenbt()
      this.$nextTick(() => {
        this.$refs.previewmaterials.initdata(item)
      })
    },
    bt3(row) {
      this.$router.push({
        path: "/home/personalcenter/texperimentalreport",
        query: {
          semester_id: this.queryParams.valueone,
          course_lib_id: row.id,
          course_lib_name: row.name,
        },
      })
    },
    bt4(item) {
      this.$refs.customdialog3.dialogopenbt()
      this.$nextTick(() => {
        this.$refs.attendancerecord.initdata(item, this.is_current_id)
      })
    },
    bt5(item) {
      this.$refs.customdialog4.dialogopenbt()
      this.$nextTick(() => {
        this.$refs.teachinggrade.initdata(item, this.is_current_id)
      })
    },
    resultsbt() {
      this.$refs.customdialog.dialogopenbt()
    },
    reportbt() {
      this.$router.push({
        path: "/home/personalcenter/experimentalreport",
      })
    },

    interactiveqabt() {
      this.$router.push({
        path: "/home/personalcenter/interactiveqa",
      })
    },
    formatSelect(_data) {
      this.optionone = _data.map(item => {
        return {
          label: item.start_year + '-' + item.end_year + " " + "学年" + " " + '─' + " " + (item.nper == 1 ? '第一学期' : '第二学期'),
          value: item.id,
          is_current: item.is_current,
          week_number: item.week_number
        }
      })
      this.optionone.forEach(el => {
        if (el.is_current == 1) {
          this.queryParams.valueone = el.value
          this.is_current_weeknum = el.week_number
          this.is_current_id = el.value
          this.getmyarrangedlist()
        }
      })
    },
    async gettreelist(params) {
      this.optionone = []
      const condition = sessionStorage.getItem('semester_all')
      if (condition) {
        let data = JSON.parse(condition)
        this.formatSelect(data.data)
      } else {
        this.queryParams.valueone = ""
        await gettreelist(params)
          .then((response) => {
            if (response.code === 0 && response.data) {
              this.formatSelect(response.data.data)
            }
          })
          .catch((error) => { })
      }
    },
    gettypeconfiglist() {
      this.courseoption = []
      let params = { type: 28 }
      gettypeconfiglist(params).then((response) => {
        this.courseoption = response.data
      }).catch((error) => {
        //console.log(error)
      })
    },
    getmyarrangedlist() {
      let params = {
        keyword: this.queryParams.keyword,
        course_classify_config_id: this.queryParams.course_classify_config_id,
        semester_id: this.is_current_id,
      }
      getmyarrangedlist(params).then((response) => {
        this.tableData = response.data.data
      }).catch((error) => {
        //console.log(error)
      })
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .virtualsimulation {
    min-height: 400px;
    .search {
      // margin-top: 40px;
      padding-left: 10px;
      padding-right: 10px;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // height: 56px;
      background: #fcfcfc;
      // border: 1px solid #ececec;
      background: #fff;
      padding-top: 15px;

      .leftcontain {
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;

        .left1 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // padding-top: 15px;
          

          .text {
            margin-right: 10px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
          }

          .elinput {
            width: 80%;

            ::v-deep .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .left2 {
          // margin-left: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 15px;
          .text {
            margin-right: 10px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
          }

          .elinput {
            width: 80%;
          }
          .el-select{
            width: 75%;
          }
        }
      }

      .rightcontain {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 15px 0px;

        .bt {
          width: 64px;
          height: 38px;
          line-height: 38px;
          background: #3d84ff;
          color: white;
          padding: 0px;
          border: 1px solid #3d84ff;
        }

        .bt1 {
          margin-right: 10px;
          background: #ffffff;
          color: #3d84ff;
        }
      }
    }

    .parentt {
      // border: 1px solid red;
      position: relative;

      // margin-top: 20px;
      // height: 600px;
      .contain {
        // border: 1px solid red;
        position: absolute;
        // width: 100%;
        // height: 100%;
        top: 0px;
        left: 0px;
        right: 0px;

        .Table {
          margin-top: 50px;
          width: 100%;

          // border: 1px solid red;
          .item-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            // margin-left: 10px;
            .title {
              margin-left: 10px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }

          .item-middle {
            .title {
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }

          .bt {
            //   margin-right: 10px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #3d84ff;
            user-select: none;
            cursor: pointer;
            padding: 0px;
            margin: 0px;
          }

          .item-right {
            display: flex;
            justify-content: space-around;
            align-items: center;

            .bt {
              //   margin-right: 10px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #3d84ff;
              user-select: none;
              cursor: pointer;
            }

            .line2 {
              margin-right: 8px;
              margin-left: 8px;
              height: 14px;
              border-right: 2px solid #cccccc;
            }
          }
        }

        .elpagination {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          .elpagination-title {
            margin-right: 4px;
          }
        }
      }
    }

    ::v-deep .Table {
      margin-top: 0px !important;
    }

    // .elpagination {
    //   margin-top: 40px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   .elpagination-title {
    //     margin-right: 4px;
    //   }
    // }
  }
}

@media screen and (min-width: 1200px) {
  .virtualsimulation {
    .search {
      // margin-top: 40px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      background: #fcfcfc;
      // border: 1px solid #ececec;
      background: #fff;

      .leftcontain {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .left1 {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .text {
            margin-right: 10px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
          }

          .elinput {
            width: 200px;

            ::v-deep .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .left2 {
          margin-left: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .text {
            margin-right: 10px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
          }

          .elinput {
            width: 300px;
          }
        }
      }

      .rightcontain {
        display: flex;
        justify-content: center;
        align-items: center;

        .bt {
          width: 64px;
          height: 38px;
          line-height: 38px;
          background: #3d84ff;
          color: white;
          padding: 0px;
          border: 1px solid #3d84ff;
        }

        .bt1 {
          margin-right: 10px;
          background: #ffffff;
          color: #3d84ff;
        }
      }
    }

    .parentt {
      // border: 1px solid red;
      position: relative;

      // margin-top: 20px;
      // height: 600px;
      .contain {
        // border: 1px solid red;
        position: absolute;
        // width: 100%;
        // height: 100%;
        top: 0px;
        left: 0px;
        right: 0px;

        .Table {
          margin-top: 50px;
          width: 100%;

          // border: 1px solid red;
          .item-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            // margin-left: 10px;
            .title {
              margin-left: 10px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }

          .item-middle {
            .title {
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }

          .bt {
            //   margin-right: 10px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #3d84ff;
            user-select: none;
            cursor: pointer;
            padding: 0px;
            margin: 0px;
          }

          .item-right {
            display: flex;
            justify-content: space-around;
            align-items: center;

            .bt {
              //   margin-right: 10px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #3d84ff;
              user-select: none;
              cursor: pointer;
            }

            .line2 {
              margin-right: 8px;
              margin-left: 8px;
              height: 14px;
              border-right: 2px solid #cccccc;
            }
          }
        }

        .elpagination {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          .elpagination-title {
            margin-right: 4px;
          }
        }
      }
    }

    ::v-deep .Table {
      margin-top: 0px !important;
    }

    // .elpagination {
    //   margin-top: 40px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   .elpagination-title {
    //     margin-right: 4px;
    //   }
    // }
  }
}
</style>
