<template>
  <!-- 我的证书-->
  <div class="virtualsimulation">
    <div class="itemcontain">
      <div class="item">
        <el-image class="image1" :src="image1" />
        <el-image @click="tocoursedetail" class="videocontain" :src="testimage" />
        <div class="title">
          <div class="text">动物营养与饲料加工</div>
        </div>
        <div class="icon-text">
          <el-image class="icon" :src="icon1" />
          <div class="text" @click="resultsbt">查看证书</div>
        </div>
      </div>

      <div class="item">
        <el-image class="image1" :src="image1" />
        <el-image @click="tocoursedetail" class="videocontain" :src="testimage" />
        <div class="title">
          <div class="text">动物营养与饲料加工</div>
        </div>
        <div class="icon-text">
          <el-image class="icon" :src="icon1" />
          <div class="text" @click="resultsbt">查看证书</div>
        </div>
      </div>
    </div>

    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>

    <Customdialog ref="customdialog" title="实验成绩" :showclose="true" width="800px">
      <div slot="dialogbody" class="dialogbody"></div>
      <div slot="dialogfooter" class="dialogfooter"></div>
    </Customdialog>
  </div>
</template>

<script>
import Customdialog from "@/components/customdialog.vue";
const cityOptions = ["仅老师参与", "回复最多"];
export default {
  name: "virtualsimulation",
  components: {
    Customdialog,
  },
  data() {
    return {
      total: 0,
      searchvalue: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/center/证书1.png"),
      icon2: require("@/assets/coursedetails/center/证书2.png"),
      image1: require("@/assets/coursedetails/center/证书3.png"),
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: true,
      tableData: [
        {
          name: "王小虎",
          score: "70",
          score2: "10",
          score3: "60",
        },
      ],
    };
  },
  methods: {
    tocoursedetail() {
      this.$router.push({
        path: "/wisdompracticeteaching/coursedetails",
      });
    },
    resultsbt() {
      this.$refs.customdialog.dialogopenbt();
    },
    previewmaterials() {
      this.$router.push({
        path: "/personalcenter/previewmaterials",
      });
    },

    preparatoryexam() {
      this.$router.push({
        path: "/personalcenter/previewexam",
      });
    },
    experimentalresults() {
      this.$refs.customdialog.dialogopenbt();
    },
    experimentalreport() {},
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },

    open() {
      this.$confirm("是否确认取消收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style scoped lang="scss">


.virtualsimulation {
  .itemcontain {
    // margin-top: 20px;
    // height: 234px;
    // background: #ffffff;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // position: relative;
    flex-wrap: wrap;
    .item {
        // border: 1px solid red;
      //   margin-top: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      width: 330px;
      height: 365px;
      position: relative;
      .image1 {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1000;
      }
      .videocontain {
        width: 100%;
        height: 262px;
        user-select: none;
        cursor: pointer;
      }
      .title {
        height: 54px;
        background: #e9f4ff;
        padding: 10px;
        box-sizing: border-box;
        .text {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #222222;
        }
      }
      .icon-text {
        height: 48px;
        background: #ebebeb;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          width: 18px;
          height: 17px;
        }
        .text {
          margin-left: 5px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }
  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .elpagination-title {
      margin-right: 4px;
    }
  }
  .dialogbody {
    padding: 20px;
    .Table {
      width: 100%;
      // border: 1px solid red;
      .item-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // margin-left: 10px;
        .title {
          margin-left: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
      .item-middle {
        .title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
      .bt {
        //   margin-right: 10px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
        padding: 0px;
        margin: 0px;
      }

      .item-right {
        display: flex;
        justify-content: center;
        align-items: center;
        .bt {
          //   margin-right: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #3d84ff;
          user-select: none;
          cursor: pointer;
        }
        .line2 {
          margin-right: 8px;
          margin-left: 8px;
          height: 14px;
          border-right: 2px solid #cccccc;
        }
      }
    }
    .elpagination {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .elpagination-title {
        margin-right: 4px;
      }
    }
  }
}
</style>
