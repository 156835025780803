<template>
  <el-form
    :model="ruleForm"
    status-icon
    :rules="rules"
    ref="ruleForm"
    label-position="right"
    label-width="140px"
    class="elform"
  >
    <el-form-item label="要绑定的手机号:" prop="mobile">
      <el-input
        class="elformitem"
        v-model="ruleForm.mobile"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item label="验证码:" prop="pass">
      <div class="btcontain">
        <el-input
          class="elformitem"
          type="password"
          v-model="ruleForm.pass"
          autocomplete="off"
        ></el-input>
        <div class="bt">
          <el-button v-if="show" class="code_text" @click="getCode">
            获取短信验证码
          </el-button>
          <el-button v-if="!show" class="getCode">{{ times }}s</el-button>
        </div>
      </div>
    </el-form-item>

    <!-- <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </el-form-item>-->
  </el-form>
</template>

<script>
import { changeusermobile, sendsmscode } from "@/api/user";
import md5 from "js-md5";
export default {
  name: "Head",
  components: {},
  props: {
    close: {
      type: Function,
    },
  },
  data() {
    var validatePass0 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };

    return {
      timer: "",
      times: 60,
      show: true,
      ruleForm: {
        mobile: "",
        pass: "",
      },
      rules: {
        pass: [{ required: true, validator: validatePass0, trigger: "blur" }],
        mobile: [{ required: true, validator: validatePass1, trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {
    this.$refs["ruleForm"].resetFields();
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    getCode() {
      if (this.ruleForm.mobile) {
        this.show = false;
        let time = new Date().getTime();
        let params = {
          mobile: this.ruleForm.mobile,
          sign: md5(md5(this.ruleForm.mobile) + time),
          time: time,
        };

        this.sendsmscode(params);

        this.timer = setInterval(() => {
          this.times--;
          if (this.times === 0) {
            this.show = true;
            clearInterval(this.timer);
          }
        }, 1000);
      } else {
        this.$message.error("请输入手机号");
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.changeusermobile({
            new_mobile: this.ruleForm.mobile,
            sms_code: this.ruleForm.pass,
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    changeusermobile(params) {
      changeusermobile(params)
        .then((response) => {
          if (response.code === 0) {
            this.close();
            if (this.timer) {
              clearInterval(this.timer);
            }
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    sendsmscode(params) {
      sendsmscode(params)
        .then((response) => {
          if (response.code === 0) {
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.elform {
  padding: 40px;
  width: 500px;
  .btcontain {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .bt {
      margin-left: 4px;
    }
  }
}
::v-deep .code_text {
  color: #409eff !important;
  padding: 10px;
}
::v-deep .getCode {
  color: #909399;
}
</style>
