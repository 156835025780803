<template>
  <div class="mainpage">
    <div class="leftcontain">
      <div class="title">基础安全类-试卷</div>
      <div class="item-contain">
        <div class="titel2">一、单选题（共1题，5.0分）</div>
        <div class="titel3">1.中国最伟大的诗人是谁（5.0分）</div>
        <div class="rediobt" v-for="(item, index) in btlist" :key="index">
          <div
            @click="clickbt1(index)"
            :class="activebtid1 === index ? 'icon iconactive' : 'icon'"
          >
            {{ item.icon }}
          </div>
          <div class="name">{{ item.name }}</div>
        </div>
        <div class="answerbox">
          <el-image class="elimage" :src="icon3"></el-image>
          <div class="rightcontain">
            <div>
              <span>考生答案:&nbsp;&nbsp;A</span
              ><span class="text">得分:&nbsp;&nbsp;0</span>
            </div>
            <div><span>正确答案:&nbsp;&nbsp;B</span></div>
          </div>
        </div>
      </div>

      <div class="item-contain">
        <div class="titel2">二、多选题（共1题，5.0分）</div>
        <div class="titel3">2.中国最伟大的诗人是谁（5.0分）</div>
        <div class="rediobt" v-for="(item, index) in btlist" :key="index">
          <div
            @click="clickbt2(index)"
            :class="activebtid2 === index ? 'icon iconactive' : 'icon'"
          >
            {{ item.icon }}
          </div>
          <div class="name">{{ item.name }}</div>
        </div>
        <div class="answerbox">
          <el-image class="elimage" :src="icon4"></el-image>
          <div class="rightcontain">
            <div>
              <span>考生答案:&nbsp;&nbsp;B,C</span
              ><span class="text">得分:&nbsp;&nbsp;5</span>
            </div>
            <div><span>正确答案:&nbsp;&nbsp;B,C</span></div>
          </div>
        </div>
      </div>

      <div class="item-contain">
        <div class="titel2">三、判断题（共1题，5.0分）</div>
        <div class="titel3">3.中国最伟大的诗人是谁（5.0分）</div>
        <el-radio-group class="radiogroup" v-model="radio">
          <el-radio :label="1">正确</el-radio>
          <el-radio :label="2">错误</el-radio>
        </el-radio-group>
        <div class="answerbox">
          <el-image class="elimage" :src="icon4"></el-image>
          <div class="rightcontain">
            <div>
              <span>考生答案:&nbsp;&nbsp;正确</span
              ><span class="text">得分:&nbsp;&nbsp;5</span>
            </div>
            <div><span>正确答案:&nbsp;&nbsp;正确</span></div>
          </div>
        </div>
      </div>

      <div class="item-contain">
        <div class="titel2">四、填空题（共1题，5.0分）</div>
        <div class="titel3">4.唐朝诗圣是___，诗仙是___。（5.0分）</div>
        <div class="rediobt">
          <div class="icon">1</div>
          <div class="name">杜甫</div>
        </div>
        <div class="rediobt">
          <div class="icon">2</div>
          <div class="name">李白</div>
        </div>
        <div class="answerbox">
          <el-image class="elimage" :src="icon3"></el-image>
          <div class="rightcontain">
            <div>
              <span>考生答案:&nbsp;&nbsp;1.杜甫;&nbsp;&nbsp;2.李白,</span
              ><span class="text">得分:&nbsp;&nbsp;0</span>
            </div>
            <div><span>正确答案:&nbsp;&nbsp;2.李白</span></div>
          </div>
        </div>
      </div>

      <!-- <div class="item-contain">
        <div class="titel2">四、简答题（共1题，5.0分）</div>
        <div class="titel3">
          5.这是题目问题，这是题目问题，这是题目问题，这是题目问题（5.0分）
        </div>
        <div class="text-editor">
          <tinymce v-model="content" :height="300" />
        </div>
        <div class="titel3">
          6.这是题目问题，这是题目问题，这是题目问题，这是题目问题（5.0分）
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Customdialog from "@/components/customdialog.vue";
import Tinymce from "@/components/Tinymce";
export default {
  name: "mainpage",
  components: {
    Customdialog,
    Tinymce,
  },
  data() {
    return {
      activebtid1: -1,
      activebtid2: -1,
      radio: 1,
      content: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/43443.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      icon3: require("@/assets/组 10.png"),
      icon4: require("@/assets/组 10 (1).png"),
      btlist: [
        {
          icon: "A",
          name: "李白",
        },
        {
          icon: "B",
          name: "李贺",
        },
        {
          icon: "C",
          name: "陆游",
        },
        {
          icon: "D",
          name: "杜牧",
        },
      ],
    };
  },
  computed: {},
  methods: {
    clickbt1(index) {
      this.activebtid1 = index;
    },
    clickbt2(index) {
      this.activebtid2 = index;
    },
    toback() {
      this.$router.push({
        path: "/personalcenter/previewexam",
        // name: "PersonalcenterMainpage",
        // params: {
        //   id: 2,
        //   componentId: "Myexperiment",
        // },
      });
    },
    todetail() {
      this.$router.push({
        path: "/personalcenter/interactiveqadetails",
      });
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  .leftcontain {
    // border: 1px solid red;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    .title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .item-contain {
      margin-top: 30px;
      .titel2 {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
      .titel3 {
        margin-top: 20px;
        margin-left: 35px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
      .rediobt {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        margin-left: 70px;
        .icon {
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border: 1px solid #cccccc;
          border-radius: 50%;
          user-select: none;
          cursor: pointer;
        }
        .iconactive {
          background: #26b5ff;
          color: white;
        }
        .name {
          margin-left: 20px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
      .answerbox {
        margin: 20px;
        margin-left: 70px;
        margin-right: 100px;
        height: 80px;
        background: #f7f7f7;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .elimage {
          margin-left: 30px;
        }
        .rightcontain {
          margin-left: 10px;
          .text {
            margin-left: 10px;
          }
        }
      }
      .radiogroup {
        margin-top: 20px;
        margin-left: 70px;
      }
      .text-editor {
        margin-top: 20px;
        margin-left: 35px;
      }
    }
  }
}
</style>
