import request from '@/utils/request'
//获取实习计划自主报名单
export function getmyplanlist(params) {
    return request({
        url: '/portal/practice-plan-student/get-plan-list',
        method: 'GET',
        params
    })
}

// 提交安全承诺书
export function uploadSafetyLetter(data) {
    return request({
        url: '/portal/practice-plan-student/upload-file',
        method: 'post',
        data
    })
}