var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"virtualsimulation"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"leftcontain"},[_c('div',{staticClass:"left1"},[_c('div',{staticClass:"text"},[_vm._v("关键字:")]),_c('el-input',{attrs:{"size":"small","placeholder":"请输入试卷名称"},model:{value:(_vm.inputvalue),callback:function ($$v) {_vm.inputvalue=$$v},expression:"inputvalue"}})],1),_c('div',{staticClass:"left2"},[_c('div',{staticClass:"text"},[_vm._v("试卷类型:")]),_c('el-select',{attrs:{"size":"small","placeholder":"请选择"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"left2"},[_c('div',{staticClass:"text"},[_vm._v("是否通过:")]),_c('el-select',{attrs:{"size":"small","placeholder":"请选择"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]),_vm._m(0)]),_c('el-table',{ref:"singleTable",staticClass:"Table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"highlight-current-row":"","stripe":"","header-cell-style":{
      'font-size': '15px',
      color: '#666666',
      'font-weight': 'bold',
      background: '#F7F7F7',
    },"row-style":{
      'font-size': '15px',
      color: '#222222',
      'font-weight': '400',
    }}},[_c('el-table-column',{attrs:{"prop":"room","label":"试卷名称"}}),_c('el-table-column',{attrs:{"prop":"time1","label":"试卷类型"}}),_c('el-table-column',{attrs:{"prop":"time2","label":"得分"}}),_c('el-table-column',{attrs:{"prop":"state","label":"是否通过"}}),_c('el-table-column',{attrs:{"prop":"state","label":"考试时间"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"item-right",on:{"click":_vm.resultsbt}},[_c('div',{staticClass:"bt"},[_vm._v("查看答题情况")])])]}}])})],1),_c('div',{staticClass:"elpagination"},[_c('div',{staticClass:"elpagination-title"},[_vm._v("共"+_vm._s(_vm.total)+"条")]),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total}})],1),_c('Customdialog',{ref:"customdialog",attrs:{"type":"table","title":"查看答题情况","showclose":true}},[_c('div',{staticClass:"dialogbody",attrs:{"slot":"dialogbody"},slot:"dialogbody"},[_c('Answer')],1),_c('div',{staticClass:"dialogfooter",attrs:{"slot":"dialogfooter"},slot:"dialogfooter"})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rightcontain"},[_c('div',{staticClass:"bt bt1"},[_vm._v("重置")]),_c('div',{staticClass:"bt"},[_vm._v("查询")])])
}]

export { render, staticRenderFns }